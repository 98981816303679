<template>
	<div>
		<div class="py-2">
			<p class="heading my-1">Display Name*</p>
			<v-text-field
				class="pa-0"
				v-model="label"
				outlined
				placeholder="Enter the label for the link"
				dense
				:error-messages="errors.label"
			></v-text-field>
			<p class="heading my-1">What is the link type?</p>
			<v-radio-group v-model="linkType" row class="mb-2">
				<v-radio label="Internal" value="RELATIVE"></v-radio>
				<v-radio label="External" value="ABSOLUTE"></v-radio>
			</v-radio-group>
			<div v-if="linkType == 'ABSOLUTE'">
				<p class="heading mb-2">External Link*</p>
				<v-text-field
					class="pa-0"
					v-model="path"
					outlined
					placeholder="Enter the path for the external link"
					dense
					:error-messages="errors.path"
				></v-text-field>
			</div>
			<div v-if="linkType != 'ABSOLUTE'">
				<p class="heading mb-2">How does it open?</p>
				<v-radio-group v-model="windowType" row class="mb-2">
					<v-radio label="Current Window" value="current"></v-radio>
					<v-radio label="New Window" value="new"></v-radio>
				</v-radio-group>
			</div>
			<div
				class="d-flex flex-row"
				style="align-items: baseline"
				v-if="linkType != 'ABSOLUTE'"
			>
				<p class="heading mb-2">Link To*:</p>
				<v-btn class="mx-3" @click="openModal = true">Browse</v-btn>
				<p>OR</p>
				<v-text-field
					class="mx-3"
					v-model="path"
					outlined
					dense
					label="Enter link relative to page"
					persistent-placeholder
					hint="Eg: /questions"
					persistent-hint
				></v-text-field>
				<v-dialog
					v-model="openModal"
					transition="dialog-top-transition"
					max-width="800px"
				>
					<add-link-modal
						@closeModal="openModal = false"
						@add-link="savePath"
					/>
				</v-dialog>
			</div>
			<p v-if="path" class="mt-2">
				Page Link: <a :href="url + path">{{ url + path }}</a>
			</p>
		</div>

		<v-footer class="footer" absolute>
			<v-btn @click="saveLink()" depressed class="save"
				>Done</v-btn
			></v-footer
		>
	</div>
</template>
<script>
import addLinkModal from './AddLinkModal'
export default {
	components: {
		'add-link-modal': addLinkModal,
	},
	props: {
		initialData: {
			type: Object,
			required: false,
		},
	},
	created() {
		if (Object.keys(this.initialData).length > 0) {
			this.linkType = this.initialData.linkType
				? this.initialData.linkType
				: 'RELATIVE'
			this.label = this.initialData.label
			this.path =
				this.initialData.path !== null ? this.initialData.path : ''
			this.uuid = this.initialData.uuid
			this.windowType =
				this.initialData.newWindow === false ? 'current' : 'new'
		}
	},
	data() {
		return {
			drawer: true,
			linkType: 'RELATIVE',
			label: '',
			openModal: false,
			path: '',
			windowType: 'current',
			disableBtn: true,
			errors: {
				label: '',
				path: '',
			},
		}
	},
	watch: {
		linkType() {
			this.path = ''
		},
		path() {
			if (this.linkType === 'RELATIVE') {
				if (this.path.match(/(^\/$)|(^\/[/a-z0-9._-]*[a-z0-9]$)/)) {
					this.errors.path = ''
				} else {
					this.errors.path = 'Please enter a valid Relative Url'
				}
			} else if (this.linkType === 'ABSOLUTE') {
				if (
					this.path.match(
						/[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)?/gi
					)
				) {
					this.errors.path = ''
				} else {
					this.errors.path = 'Please enter a valid Absolute Url'
				}
			}
		},
		label() {
			this.errors.label = ''
		},
	},
	computed: {
		url() {
			let domain = this.$store.getters['app/getCurrentProjectDomain']
			let url = `https://${domain}`
			return url
		},
	},
	methods: {
		savePath(link) {
			this.path = link
			this.openModal = false
		},
		saveLink() {
			if (this.label !== '' && this.path !== '') {
				let payload = {
					label: this.label,
					path: this.path,
					linkType: this.linkType,
					newWindow: this.windowType === 'current' ? false : true,
					uuid: this.uuid,
				}
				this.$emit('savedLink', payload)
				this.drawer = !this.drawer
			} else {
				if (this.label === '') {
					this.errors.label = 'Please Enter Label'
				} else {
					if (this.path !== '') {
						if (this.linkType === 'RELATIVE') {
							if (
								this.path.match(
									/(^\/$)|(^\/[/a-z0-9._-]*[a-z0-9]$)/
								)
							) {
								this.errors.path = ''
							} else {
								this.errors.path =
									'Please enter a valid Relative Url'
							}
						} else if (this.linkType === 'ABSOLUTE') {
							if (
								this.path.match(/(^\/[/a-z0-9._-]*[a-z0-9]$)/)
							) {
								this.errors.path = ''
							} else {
								this.errors.path =
									'Please enter a valid Absolute Url'
							}
						}
					}
				}
			}
		},
	},
}
</script>
<style scoped>
.heading {
	color: #7a8994;
}
.footer {
	background-color: var(--blue);
	height: 60px;
}
.save {
	background-color: var(--blue) !important;
	color: white;
	width: 100% !important;
	height: 100% !important;
}
</style>
