<template>
	<div class="filters">
		<div class="py-4">
			<div class="pb-1 mb-4 header">
				<div
					class="
						px-8
						d-flex
						flex-row
						align-start
						justify-space-between
					"
				>
					<h1 class="crud-title">Customize Your Website's Footer</h1>
				</div>
			</div>
			<div class="px-8">
				<p class="mb-3 section-heading">Description</p>
				<v-textarea
					name="description"
					outlined
					auto-grow
					v-model="aboutText"
					placeholder="Write a brief description about your Website."
				></v-textarea>
				<ListOfSections
					@on-sections-update="updateLinkSegments($event)"
					:initial-data="linkSegments"
				></ListOfSections>
				<ContactUs
					@on-contacts-update="updateContactUs($event)"
					:initial-data="contactUs"
				></ContactUs>
			</div>
		</div>
		<div class="d-flex flex-row px-8 pb-5">
			<v-btn
				@click="saveFooter()"
				depressed
				class="save"
				:loading="loading"
				>save</v-btn
			>
		</div>
	</div>
</template>
<script>
import ListOfSections from './components/ListOfSections'
import ContactUs from './components/ContactUs'

export default {
	components: {
		ListOfSections,
		ContactUs,
	},
	data() {
		return {
			aboutText: '',
			linkSegments: [],
			contactUs: [],
			loading: false,
		}
	},
	created() {
		this.getFooter()
	},
	methods: {
		updateLinkSegments(sections) {
			this.linkSegments = sections
		},
		updateContactUs(contacts) {
			this.contactUs = contacts
		},
		getFooter() {
			const projectId = this.$store.state.app.projectId
			this.axios({
				method: 'get',
				url: `/project/${projectId}/footer`,
			}).then((res) => {
				let footer = res.data
				this.aboutText = footer.aboutText
				this.contactUs = footer.contactUs
				this.linkSegments = footer.linkSegments.map((section) => ({
					heading: section.heading,
					links: section.links,
				}))
			})
		},
		saveFooter() {
			this.loading = true
			const projectId = this.$store.state.app.projectId
			let payload = {
				aboutText: this.aboutText,
				linkSegments: this.linkSegments,
				contactUs: this.contactUs,
			}
			this.axios({
				method: 'put',
				url: `/project/${projectId}/footer`,
				data: payload,
			}).then((res) => {
				console.log(res)
				this.loading = false
			})
		},
	},
}
</script>
<style scoped>
.footer-drawer {
	width: 50% !important;
}
.section-heading {
	font-size: 1.2rem;
}
.close-icon {
	font-size: 1.7rem;
	color: #b9b9b9;
}
.close-icon:hover {
	color: black;
	cursor: pointer;
}
.header {
	border-bottom: 1px solid #eee;
}
.save {
	background-color: var(--blue) !important;
	color: white;
}
</style>
