<template>
	<div>
		<div class="d-flex align-end justify-space-between mb-5">
			<p class="section-heading">Add Sections</p>
			<v-btn depressed class="add" @click="addSection()">
				+ Add Section
			</v-btn>
		</div>
		<draggable
			v-model="sections"
			class="drag-area"
			ghost-class="ghost"
			@change="emitData()"
		>
			<transition-group name="sortable">
				<SectionCard
					v-for="section in sections"
					:key="section.uuid"
					:initialData="section"
					@remove-section="removeSection($event)"
					@on-section-update="updateSectionsArray($event)"
				></SectionCard>
			</transition-group>
		</draggable>
	</div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid'

import SectionCard from './SectionCard'

import draggable from 'vuedraggable'
export default {
	components: {
		SectionCard,
		draggable,
	},
	props: {
		initialData: {
			type: Array,
		},
	},
	watch: {
		initialData() {
			this.sections = this.initialData.map((section) => ({
				heading: section.heading,
				links: section.links,
				uuid: uuidv4(),
			}))
		},
	},
	data() {
		return {
			sections: [
				{
					heading: '',
					links: [],
					uuid: uuidv4(),
				},
			],
		}
	},
	methods: {
		addSection() {
			let obj = {
				heading: '',
				links: [],
				uuid: uuidv4(),
			}
			let arr = this.sections
			arr.push(obj)
			this.sections = arr
		},
		updateSectionsArray(emittedObj) {
			let sections = this.sections
			sections.every(function (section, index) {
				if (section.uuid === emittedObj.uuid) {
					let obj = {
						heading: emittedObj.heading,
						links: emittedObj.links,
						uuid: emittedObj.uuid,
					}
					sections[index] = obj
					// vm.$set(vm.facilities, index, obj);
					return false // to break the loop
				} else {
					return true
				}
			})
			this.sections = sections
			this.emitData()
		},
		removeSection(sectionId) {
			let sections = this.sections

			sections.forEach((section, index) => {
				if (section.uuid === sectionId) {
					sections.splice(index, 1)
					return false // to break the loop
				} else {
					return true
				}
			})
			this.sections = sections
			this.emitData()
		},
		emitData() {
			let payload = this.sections.map((section) => ({
				heading: section.heading,
				links: section.links,
			}))
			this.$emit('on-sections-update', payload)
		},
	},
}
</script>
<style scoped>
.add {
	background-color: var(--blue) !important;
	color: white;
}
.section-heading {
	font-size: 1.2rem;
}
.sortable-move {
	transition: transform 1s;
}
</style>
