<template>
	<div>
		<v-card class="pa-4">
			<v-row>
				<v-col cols="4">
					<p class="heading ml-3">Link to:</p>
					<v-radio-group
						v-model="selectedDomain"
						class="d-flex flex-column"
					>
						<v-radio
							class="ma-2"
							label="Home"
							value="home"
						></v-radio>
						<v-radio
							class="ma-2"
							label="About Us"
							value="about-us"
						></v-radio>
						<v-radio
							class="ma-2"
							label="Services"
							value="services"
						></v-radio>
						<v-radio
							class="ma-2"
							label="Blogs"
							value="blogs"
						></v-radio>
						<v-radio
							class="ma-2"
							label="Professional"
							value="professional"
						></v-radio>
						<v-radio
							class="ma-2"
							label="Case Study"
							value="case-study"
						></v-radio>
						<v-radio
							class="ma-2"
							label="Testimonials"
							value="testimonials"
						></v-radio>
						<v-radio
							class="ma-2"
							label="Gallery"
							value="gallery"
						></v-radio>
						<v-radio
							class="ma-2"
							label="Media Coverage"
							value="media-coverage"
						></v-radio>
						<v-radio
							class="ma-2"
							label="Questions"
							value="questions"
						></v-radio>
						<v-radio
							class="ma-2"
							label="Contact"
							value="contact"
						></v-radio>
					</v-radio-group>
				</v-col>
				<v-spacer></v-spacer>
				<v-divider class="mx-4" vertical></v-divider>
				<v-col cols="7">
					<div v-if="selectedDomain">
						<h3>
							{{
								selectedDomain.charAt(0).toUpperCase() +
								selectedDomain.slice(1)
							}}
						</h3>
						<p>
							Page link is:
							<a class="mt-2" :href="`${url}${getLink}`">
								{{ `${url}${getLink}` }}
							</a>
						</p>
						<div v-if="isDomainSubsectionAllowed()">
							<v-checkbox
								v-model="domainSubsectionSelected"
								label="Add Subsection"
							></v-checkbox>
							<v-select
								v-if="domainSubsectionSelected"
								v-model="selectedSubsection"
								:items="subsectionList"
								:error-messages="subsectionListError"
								label="Select Subsection"
							></v-select>
						</div>
					</div>
				</v-col>
			</v-row>
			<v-divider class="mx-4" inset></v-divider>
			<div class="d-flex flex-row ma-4">
				<v-btn @click="closeModal">Cancel</v-btn>
				<v-spacer></v-spacer>
				<v-btn
					:disabled="selectedDomain === null"
					@click="addLink"
					color="primary"
					>Done</v-btn
				>
			</div>
		</v-card>
	</div>
</template>
<script>
import * as _ from 'lodash'
export default {
	data() {
		return {
			selectedDomain: null,
			domainSubsectionSelected: false,
			allowedDomainSubsection: [
				'services',
				'blogs',
				'professional',
				'case-study',
			],
			subsectionList: [],
			selectedSubsection: null,
			domainLinks: {},
			subsectionListError: null,
		}
	},
	created() {
		this.getDomainLinks()
	},
	computed: {
		getLink() {
			if (this.selectedDomain)
				if (this.domainSubsectionSelected && this.selectedSubsection) {
					return `/${this.selectedDomain}/${this.selectedSubsection}`
				} else if (this.selectedDomain === 'home') {
					return `/`
				}
			return `/${this.selectedDomain}`
		},
		url() {
			let domain = this.$store.getters['app/getCurrentProjectDomain']
			let url = `https://${domain}`
			return url
		},
	},
	watch: {
		selectedDomain() {
			this.selectedSubsection = null
			this.domainSubsectionSelected = false
			this.subsectionList = this.domainLinks[this.selectedDomain] || null
		},
		selectedSubsection() {
			this.subsectionListError = null
		},
	},
	methods: {
		isDomainSubsectionAllowed() {
			return _.includes(this.allowedDomainSubsection, this.selectedDomain)
		},
		getDomainLinks() {
			const projectId = this.$store.state.app.projectId

			this.axios({
				method: 'get',
				url: `/project/${projectId}/navbar/links`,
			}).then((res) => {
				this.domainLinks = res.data
			})
		},
		addLink() {
			if (this.domainSubsectionSelected && !this.selectedSubsection) {
				this.subsectionListError = 'Please select Subsection link'
				return
			}
			this.$emit('add-link', `${this.getLink}`)
		},
		closeModal() {
			this.$emit('closeModal')
		},
	},
}
</script>
<style scoped></style>
